<script>
    import Auth from './components/Auth.svelte'
    import Form from './components/Form.svelte'
    import Finish from './components/Finish.svelte'

    // let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoidjM1NzEyMzEyMyIsIm5hbWUiOnsicHJlZml4IjozNTcsIm51bWJlciI6MTIzMTIzfX0.ndlcldbWRiJFYm7RGMOVoGXUOYGmZCyrSpRJwWr4-gY';
    // let step = 2;
    let token;
    let step = 1;
    let publishFeedback;

    function confirmAuth(event) {
        token = event.detail.token;
        step = 2;
    }

    function completeForm(event) {
        publishFeedback = event.detail.publishFeedback;
        step = 3;
    }

</script>

<main>
    <div class="row w-100 justify-content-center">
        <div class="col-xxl-8 col-xl-9 col-lg-6 col-md-8 {step===1 ? '': 'd-none' }">
            <div class="card">
                <Auth on:confirmAuth={confirmAuth}></Auth>
            </div>
        </div>
        <div class="col-xxl-8 col-md-10 {step===2 ? '': 'd-none' }">
            <div class="card">
                <Form on:completeForm={completeForm} token={token}></Form>
            </div>
        </div>
        <div class="col-xxl-8 col-md-10 {step===3 ? '': 'd-none' }">
            <div class="card">
                <Finish publishFeedback={publishFeedback}></Finish>
            </div>
        </div>
    </div>
</main>
