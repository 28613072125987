<script>
    export let publishFeedback
</script>

<main>
    <div class="w-100 position-relative text-center pt-4">

        <span class="fw-bold fs-5 text-primary">
              {publishFeedback}
        </span>
        <div class="mt-3">
            <img width="100" class="img-fluid" src="/static/icons/nurse.png" alt="">
        </div>
    </div>
</main>
