<script>
    import {onMount} from "svelte";
    import Cookies from 'js-cookie';
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    let countries = [];
    let prefix = 357;
    let number = '';
    let token;
    let loading;
    let loginLoading;

    onMount(async () => {

        document.querySelectorAll('.form-outline').forEach((formOutline) => {
            new mdb.Input(formOutline).init();
        });

        new mdb.Select(document.getElementById('prefix'), {
            'validation': true,
            'valid-feedback': "",
            'invalid-feedback': 'invalid',
            'filter': true
        });

        const countriesRes = await fetch(`https://public.scoolmed.com/countries`);
        countries = await countriesRes.json();
    });


    let phoneError;
    let phoneInit = false;
    let phoneChecked;
    let sendBtnDisabled = true;
    let disableSendOTP = true

    function validatePhone() {
        phoneChecked = false;
        phoneError = false;

        if (phoneInit) {
            if (number.length === 0) {
                return phoneError = "Phone number is required"
            } else if (number.length <= 5) {
                return phoneError = "The phone number must be at least 5 digits long"
            } else if (number.length >= 15) {
                return phoneError = "The phone number can be up to 15 digits long"
            } else if (!(/^\d+$/.test(number.replace(/\s/g, "")))) {
                return phoneError = "The phone number can only contain numbers"
            } else if (prefix === 0) {
                return phoneError = "You have to choose country code"
            }
            disableSendOTP = false
            phoneChecked = true;
        } else {
            phoneInit = true;
        }
    }

    $: number, prefix, validatePhone();

    let feedback;

    let mobileSend = false;

    let resendBtnDisabled;

    let returnField;

    async function sendOTP() {
        resendBtnDisabled = true;
        loading = true;
        feedback = null;
        const SubmitFormRes = await fetch(`https://public.home-care.app/rpc/totp_send`, {
            method: "POST",
            body: JSON.stringify({
                "_mobile": `(${prefix}, ${number})`
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
        if (SubmitFormRes.status === 200) {
            feedback = await SubmitFormRes.json();
            loading = false;
            mobileSend = true;
            setTimeout(function () {
                selectField(otp_1El)
            }, 500);
        } else {
            feedback = await SubmitFormRes.json();
            loading = false;
        }
    }

    let otp_1El;
    let otp_2El;
    let otp_3El;
    let otp_4El;

    let otpNum1;
    let otpNum2;
    let otpNum3;
    let otpNum4;
    let fullOTP;

    $: if (otpNum1 || otpNum1 === 0) {
        otpNum1 = String(otpNum1).charAt(0);
    }
    $: if (otpNum2 || otpNum2 === 0) {
        otpNum2 = String(otpNum2).charAt(0);
    }
    $: if (otpNum3 || otpNum3 === 0) {
        otpNum3 = String(otpNum3).charAt(0);
    }
    $: if (otpNum4 || otpNum4 === 0) {
        otpNum4 = String(otpNum4).charAt(0);
    }

    $: fullOTP = otpNum1 + otpNum2 + otpNum3 + otpNum4;


    function blockString(evt) {
        if ((evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57) {
            evt.preventDefault();
        }
    }

    function nextField(event, num, el_next) {
        if (el_next === null && (event.keyCode === 13)) {
            login()
        } else if (event.shiftKey && event.keyCode === 9) {
            returnField = true;
        } else if (returnField) {
            returnField = false;
        } else if (num) {
            let numCode = num.charCodeAt(0);
            if (numCode >= 48 && numCode <= 57 && el_next !== null) {
                el_next.select();
            }
        }
    }

    function disableLogin() {
        sendBtnDisabled = true;
        if (otpNum1 && otpNum2 && otpNum3 && otpNum4) {
            if (fullOTP.length === 4) {
                sendBtnDisabled = false
            }
        }
    }

    function selectField(el) {
        el.select()
    }

    $: disableLogin(), otpNum1, otpNum2, otpNum3, otpNum4;
    let resendCount = 0;

    $: if (mobileSend || resendCount > 0) {
        setTimeout(function () {
            resendBtnDisabled = false;
            resendCount += 1
        }, 15000);
    }

    async function login() {
        loginLoading = true;
        feedback = null;
        fullOTP = otpNum1 + otpNum2 + otpNum3 + otpNum4
        const SubmitFormRes = await fetch(`https://public.home-care.app/rpc/totp_login`, {
            method: "POST",
            body: JSON.stringify({
                "_mobile": `(${prefix}, ${number})`,
                "_otp": fullOTP,
                "_vector": "SBM"
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
        if (SubmitFormRes.status === 200) {
            feedback = await SubmitFormRes.json();
            loginLoading = false;
            token = feedback.token;
            Cookies.set('token', token, 300);
            dispatch('confirmAuth', {
                token: token
            });
        } else {
            feedback = await SubmitFormRes.json();
            loginLoading = false;
        }
    }

</script>

<main>
    <div class="bg-primary text-white py-3 px-4 card-header">
        <h4 class="mb-0">
            Need Nurse Or Doctor?
        </h4>
    </div>
    <div class="w-100 position-relative p-4">
        {#if mobileSend}
            <div class="d-flex justify-content-between align-items-center mb-1">
                <a href="/" class="fw-bold fs-5 text-primary">
                    +{prefix} {number}
                </a>
                <b class="ms-1 text-primary"> (Step 2/3) </b>
            </div>
            <div class="mb-4">
                <div>
                    <span class="small">
                        We sent you SMS with the OTP code.
                    </span>
                </div>
            </div>
        {:else}
            <div class="d-flex justify-content-between align-items-center mb-1">
                <span class="fw-bold fs-5 text-primary">
                    Mobile Number
                </span>
                <b class="ms-1 text-primary"> (Step 1/3) </b>
            </div>
            <div class="mb-4">
                <div>
                    <span class="small">
                        We will send you SMS with the OTP code.
                    </span>
                </div>
            </div>
            <div class="input-box input-margin">
                <select
                        id="prefix"
                        class="select border-primary"
                        bind:value={prefix}
                >
                    <option value="{prefix}">
                        +{prefix}
                    </option>
                    {#each countries as country}
                        <option value="{country.mobile_prefix}">{country.name} (+{country.mobile_prefix})
                        </option>
                    {/each}
                </select>
                <input class="m-0 number-control {phoneError ? 'form-control is-invalid active': phoneChecked ?'form-control is-valid active':'form-control'}"
                       id="number" type="tel" placeholder="123123123"
                       bind:value={number}/>
                <div class="invalid-feedback position-absolute">{ phoneError }</div>
            </div>
            <div class="d-flex justify-content-between">
                <button class="btn btn-outline-primary fw-bold" data-mdb-toggle="modal" data-mdb-target="#modal">
                    How it works?
                </button>
                <!-- Submit button -->
                <button type="submit"
                        class="btn btn-primary btn-block fw-bold w-auto {loading ? 'active' : ''} {phoneError || disableSendOTP ? 'disabled' : ''}"
                        on:click={sendOTP}
                >
                    Send OTP
                    {#if loading}
                        <span class="spinner-border text-primary ms-2" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </span>
                    {/if}
                </button>
            </div>
        {/if}
    </div>
    <div class="p-4 {!mobileSend ? 'd-none' : ''}">
        <div class="text-center mb-3">
            <span class="fw-normal fs-6 d-inline fw-bold">
              Enter OTP
            </span>
        </div>
        <div class="input-group otp-input mx-auto input-margin">
            <input
                    bind:this={otp_1El}
                    bind:value={otpNum1}
                    type="number"
                    class="form-control shadow-4"
                    aria-label="OTPnum1"
                    aria-describedby="OTPnum1"
                    maxlength="1"
                    on:keyup={(event) => nextField(event, otpNum1, otp_2El)}
                    on:keypress={blockString}
                    on:click={selectField(otp_1El)}
            />
            <input
                    bind:this={otp_2El}
                    bind:value={otpNum2}
                    type="number"
                    class="form-control shadow-4"
                    aria-label="OTPnum2"
                    aria-describedby="OTPnum2"
                    maxlength="1"
                    on:keyup={(event) => nextField(event, otpNum2, otp_3El)}
                    on:keypress={blockString}
                    on:click={selectField(otp_2El)}
            />
            <input
                    bind:this={otp_3El}
                    bind:value={otpNum3}
                    type="number"
                    class="form-control shadow-4"
                    aria-label="OTPnum3"
                    aria-describedby="OTPnum3"
                    maxlength="1"
                    on:keyup={(event) => nextField(event, otpNum3, otp_4El)}
                    on:keypress={blockString}
                    on:click={selectField(otp_3El)}
            />
            <input
                    bind:this={otp_4El}
                    bind:value={otpNum4}
                    type="number"
                    class="form-control shadow-4"
                    aria-label="OTPnum4"
                    aria-describedby="OTPnum4"
                    maxlength="1"
                    on:keyup={(event) => nextField(event, otpNum4, null)}
                    on:keypress={blockString}
                    on:click={selectField(otp_4El)}
            />
        </div>
        <div class="d-flex justify-content-between align-items-end">
            <span class="text-decoration-underline resend-link {loading ? 'active-link' : ''} {resendBtnDisabled ? 'disable-link' : ''}"
                  on:click={sendOTP}
            >
                re-send OTP code
                {#if loading}
                    <span class="spinner-border text-primary ms-2" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </span>
                {/if}
            </span>
            <!-- Submit button -->
            <button type="submit"
                    class="btn btn-primary btn-block fw-bold w-auto start-filling-form {loginLoading ? 'active' : ''} {sendBtnDisabled ? 'disabled' : ''}"
                    on:click={login}
            >
                Next
                {#if loginLoading}
                    <span class="spinner-border text-primary ms-2" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </span>
                {/if}
            </button>
        </div>
    </div>
    {#if feedback}
        <div class="mt-4">
            {#if feedback.hint}
                    <span class="small d-block text-start" style="left: 0;">hint: <span
                            class="text-info">{feedback.hint}</span></span>
            {/if}
            {#if feedback.details}
                    <span class="small d-block text-start" style="left: 0;">details: <span
                            class="text-danger">{feedback.details}</span></span>
            {/if}
            {#if feedback.code}
                    <span class="small d-block text-start" style="left: 0;">code: <span
                            class="text-info">{feedback.code}</span></span>
            {/if}
            {#if feedback.message}
                    <span class="small d-block text-start" style="left: 0;">message: <span
                            class="text-info">{feedback.message}</span></span>
            {/if}
        </div>
    {/if}

    <!-- Modal -->
    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body p-md-5">
                    <img class="img-fluid" src="/static/img/index/side-img.png" alt="">
                </div>
                <div class="modal-footer d-flex justify-content-center align-items-center">
                    <button type="button" class="btn btn-primary fw-bold" data-mdb-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</main>

