<script>
    import {onMount} from "svelte";
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    export let token;

    let districts = [];
    let cities = [];

    let selectedDistrict;
    let municipality;

    let districtsLoading = false;

    let selectError;
    let selectChecked;

    let docSpecError;
    let docSpecChecked;

    onMount(async () => {
        document.querySelectorAll('.form-outline').forEach((formOutline) => {
            new mdb.Input(formOutline).init();
        });

        const districtSelect = new mdb.Select(document.getElementById('district'), {
            'filter': true,
            'placeholder': 'District',
        });

        const docSpec = new mdb.Select(document.getElementById('docSpec'));

        document.getElementById('district').addEventListener('open.mdb.select', async (e) => {
            if (!postalCodeError) {
                districtsLoading = true;
                const getDistricts = await fetch('https://api.home-care.app/Districts', {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    },
                });
                if (getDistricts.status === 200) {
                    response = await getDistricts.json();
                    districts = response;
                    districtsLoading = false;
                } else {
                    districtsLoading = false;
                }
                const asyncAutocomplete = document.querySelector('#async');
                new mdb.Autocomplete(asyncAutocomplete, {
                    filter: getCities,
                    displayValue: (value) => value.name
                });
            }
        });
        document.getElementById('district').addEventListener('close.mdb.select', (e) => {
            selectChecked = true;
            selectError = "Choose district"
        });

        document.getElementById('docSpec').addEventListener('close.mdb.select', (e) => {
            docSpecChecked = true;
            docSpecError = "Choose doctor's specialization"
        });
        document.getElementById('district').addEventListener('valueChange.mdb.select', (e) => {
            city = '';
            municipality = '';
        });

        document.getElementById('async').addEventListener('itemSelect.mdb.autocomplete', (e) => {
            if (e.value.name) {
                city = e.value.name;
            }
        })

        selectPostal()
    });

    let districtDisabled = true;
    let specificDisabled = true;

    $: if (selectedDistrict !== undefined) {
        specificDisabled = false
    }

    let nurseBtn = false;
    let doctorBtn = false;

    let docSpecList = [
        {
            id: 1,
            name: 'Allergy and immunology'
        },
        {
            id: 2,
            name: 'Anesthesiology'
        },
        {
            id: 3,
            name: 'Dermatology'
        },
        {
            id: 4,
            name: 'Diagnostic radiology'
        },
        {
            id: 5,
            name: 'Emergency medicine'
        },
        {
            id: 6,
            name: 'Family medicine'
        },
    ]

    let docSpec;

    function needNurse() {
        nurseBtn = true;
        doctorBtn = false;
    }

    function needDoctor() {
        doctorBtn = true;
        nurseBtn = false
    }

    function selectPostal() {
        postalCodeChecked = false;
        document.getElementById('no_postal_code').select()
    }

    function changePostalCode() {
        zipData = null
        districtDisabled = true;
        specificDisabled = true
        postalCode = undefined;
        selectChecked = false;
        selectedDistrict = undefined;
        city = undefined;
        municipality = undefined;
    }

    let loading;
    let response;
    let zipData = null;

    let postalCode;
    let postalCodeError;
    let postalCodeInit = false;
    let postalCodeChecked;

    function validatePostalCode(value) {
        postalCodeChecked = false;
        postalCodeError = false;

        if (postalCodeInit) {
            if (String(value).trim().length === 0) {
                return postalCodeError = "This field is required"
            } else if (String(value).trim().length > 150) {
                return emailError = "Invalid"
            }
            postalCodeChecked = true;
        } else {
            postalCodeInit = true;
        }
    }

    $: validatePostalCode(postalCode), postalCode;

    let city;
    let cityError;
    let cityInit = false;
    let cityChecked;

    function validateCity() {
        cityChecked = false;
        cityError = false;
        if (cityInit) {
            if (String(city).trim().length === 0) {
                return cityError = "This field is required"
            } else if (String(city).trim().length > 150) {
                return cityError = "Invalid"
            }
            cityChecked = true;
        } else {
            cityInit = true;
        }
    }

    $: validateCity(), city;

    let patientName;
    let nameError;
    let nameInit = false;
    let nameChecked;

    function validateName() {
        nameChecked = false;
        nameError = false;
        if (nameInit) {
            if (String(patientName).trim().length === 0) {
                return nameError = "This field is required"
            } else if (String(patientName).trim().length > 150) {
                return nameError = "Invalid"
            }

            nameChecked = true;
        } else {
            nameInit = true;
        }
    }

    $: validateName(), patientName;

    let patientEmail;
    let emailError;
    let emailInit = false;
    let emailChecked;
    let emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    function validateEmail() {
        emailChecked = false;
        emailError = false;
        if (emailInit) {
            if (String(patientEmail).trim().length === 0) {
                return emailError = "This field is required"
            } else if (String(patientEmail).trim().length > 150) {
                return emailError = "Invalid"
            } else if (!String(patientEmail).trim().match(emailformat)) {
                return emailError = "Wrong email address format"
            }
            emailChecked = true;
        } else {
            emailInit = true;
        }
    }

    $: validateEmail(), patientEmail;

    let patientNurseDesc;
    let nurseDescError;
    let nurseDescInit = false;
    let nurseDescChecked;

    function validateNurseDesc() {
        nurseDescChecked = false;
        nurseDescError = false;
        if (nurseDescInit) {
            if (String(patientNurseDesc).trim().length === 0) {
                return nurseDescError = "This field is required"
            } else if (String(patientNurseDesc).trim().length > 150) {
                return nurseDescError = "Invalid"
            }
            nurseDescChecked = true;
        } else {
            nurseDescInit = true;
        }
    }

    $: validateNurseDesc(), patientNurseDesc;

    let patientDocDesc;
    let docDescError;
    let docDescInit = false;
    let docDescChecked;

    function validateDocDesc() {
        docDescChecked = false;
        docDescError = false;

        if (docDescInit) {
            if (String(patientDocDesc).trim().length === 0) {
                return docDescError = "This field is required"
            } else if (String(patientDocDesc).trim().length > 150) {
                return docDescError = "Invalid"
            }
            docDescChecked = true;
        } else {
            docDescInit = true;
        }
    }

    $: validateDocDesc(), patientDocDesc;

    async function getZipset() {
        if (!postalCodeError) {
            loading = true;
            const getZipset = await fetch('https://api.home-care.app/rpc/GetZipset', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify({
                    "_zip": postalCode,
                }),
            });
            if (getZipset.status === 200) {
                response = await getZipset.json();
                zipData = response;
                selectedDistrict = zipData.district
                city = zipData.city
                municipality = zipData.municipality
                loading = false;
            } else {
                districtDisabled = false;
                loading = false;
            }
        }
    }

    async function getCities(query) {
        const url = `https://api.home-care.app/Cities?district_name=eq.${selectedDistrict}&name=ilike.${encodeURI(query)}*`;
        const response = await fetch(url,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
            });
        cities = response.json();
        return cities;
    }

    let feedback;

    async function publishWebform() {
        loading = true;
        if (nurseBtn) {
            const webForm = await fetch('https://api.home-care.app/rpc/PublishWebform', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify({
                    "_email": patientEmail,
                    "_description": patientNurseDesc,
                    "_display_name": patientName,
                    "_zip": postalCode,
                    "_city": city,
                    "_municipality": municipality,
                    "_district": selectedDistrict
                }),
            });
            if (webForm.status === 200) {
                response = await webForm.json();
                dispatch('completeForm', {
                    publishFeedback: response.msg
                });
                loading = false;
                window.scrollTo(0, 0);
            } else {
                feedback = await webForm.json();
                loading = false;
            }
        } else if (doctorBtn) {
            patientDocDesc = patientDocDesc + "\n\n" + docSpec
            const webForm = await fetch('https://api.home-care.app/rpc/PublishWebform', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify({
                    "_email": patientEmail,
                    "_description": patientDocDesc,
                    "_display_name": patientName,
                    "_zip": postalCode,
                    "_city": city,
                    "_municipality": municipality,
                    "_district": selectedDistrict
                }),
            });
            if (webForm.status === 200) {
                response = await webForm.json();
                dispatch('completeForm', {
                    publishFeedback: response.msg
                });
                loading = false;
                window.scrollTo(0, 0);
            } else {
                feedback = await webForm.json();
                loading = false;
            }
        }
    }

    $: publishDisabled = ((postalCodeError || !postalCode) || (selectedDistrict === undefined) || (docSpec === "") || (cityError || !city) || (nameError || !patientName) || (emailError || !patientEmail) || ((nurseDescError || (!patientNurseDesc && nurseBtn)) || (docDescError || (!patientDocDesc && doctorBtn))));
</script>

<main>
    <div class="w-100 position-relative form-section p-4">
        <div class="d-flex justify-content-between align-items-center mb-1">
            <span class="fw-bold fs-5 text-primary">
                Where are you?
            </span>
            <b class="ms-1 text-primary"> (Step 3/3) </b>
        </div>
        <div class="mb-4">
            <div>
                <span class="small">
                    We will inform any nurse/doctor in your area who could help.
                </span>
            </div>
        </div>
        <div class="mask d-flex align-items-center justify-content-center {!loading ? 'd-none' : ''}"
             style="z-index: 99999; background-color: rgba(255,255,255,1)">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="row w-100 {!zipData ? 'd-none': ''}">
            <div class="col-6 mb-4 text-center">
                <span class="small-heading d-block">
                    Postal Code
                </span>
                <span class="text-primary fw-bold d-block">
                    {#if zipData}
                        {zipData.zip_code}
                    {/if}
                </span>
            </div>
            <div class="col-6 mb-4 text-center">
                    <span class="small-heading d-block">
                        District
                    </span>
                <span class="text-primary fw-bold d-block">
                    {#if zipData}
                        {zipData.district}
                    {/if}
                    </span>
            </div>
            <div class="col-6 text-center">
                <span class="small-heading d-block">
                    City
                </span>
                <span class="text-primary fw-bold d-block">
                    {#if zipData}
                        {zipData.city}
                    {/if}
                </span>
            </div>
            <div class="col-6 text-center">
                <span class="small-heading d-block">
                    Municipality
                </span>
                <span class="text-primary fw-bold d-block">
                    {#if zipData}
                        {zipData.municipality}
                    {:else if zipData}
                        {zipData.city}
                    {/if}
                </span>
            </div>
            <div class="text-center mt-3 mb-4">
                <button class="btn btn-primary btn-sm"
                        on:click={changePostalCode}
                        on:click={selectPostal}
                >
                    Change Postal Code
                </button>
            </div>
        </div>
        <div class="row {zipData ? 'd-none' : ''}">
            <div class="col-6 col-lg-3 col-xl-6">
                <div class="form-outline mb-4 shadow-4 position-relative">
                    <input type="text" id="no_postal_code"
                           class="{postalCodeError ? 'form-control is-invalid active': postalCodeChecked ?'form-control is-valid active':'form-control'}"
                           placeholder="Postal Code"
                           bind:value={postalCode}
                           on:change={getZipset}
                    />
                    <div class="invalid-feedback position-absolute">{ postalCodeError }</div>
                </div>
            </div>
            <div class="col-6 col-lg-3 col-xl-6">
                <div class="select-control shadow-4 mb-4 position-relative {!selectedDistrict && selectChecked ? 'invalid-select' : ''}">
                    <select
                            id="district"
                            class="select form-control"
                            disabled={districtDisabled}
                            bind:value={selectedDistrict}
                            on:change={getCities}
                            on:change={() =>  city = undefined}
                    >
                        {#if !districtsLoading}
                            {#each districts as district}
                                <option class="text-danger" value="{district.name}">{district.name}</option>
                            {/each}
                        {:else}
                            <option value="" disabled>
                                <span class="visually-hidden">Loading...</span>
                            </option>
                        {/if}
                    </select>
                    {#if !selectedDistrict && selectChecked}
                        <span class="position-absolute select-error position-absolute text-danger">{ selectError }</span>
                    {/if}
                </div>
            </div>
            <div class="col-6 col-lg-3 col-xl-6">
                <div id="async" class="form-outline mb-4 shadow-4 autocomplete">
                    <input type="text" id="city"
                           class="{cityError ? 'form-control is-invalid active': cityChecked ? 'form-control is-valid active' : 'form-control'}"
                           placeholder="City"
                           bind:value={city}
                           disabled={specificDisabled}
                    />
                    <div class="invalid-feedback position-absolute">{ cityError }</div>
                </div>
            </div>
            <div class="col-6 col-lg-3 col-xl-6">
                <div class="form-outline mb-4 shadow-4">
                    <input type="text" id="municipality" class="form-control" placeholder="Municipality"
                           bind:value={municipality}
                           disabled={specificDisabled}
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-outline mb-4 shadow-4">
                    <input type="text" id="displayName"
                           class="{nameError ? 'form-control is-invalid active': nameChecked ?'form-control is-valid active':'form-control'}"
                           placeholder="Patient Name"
                           bind:value={patientName}
                    />
                    <div class="invalid-feedback position-absolute">{ nameError }</div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-outline mb-4 shadow-4">
                    <input type="text" id="email"
                           class="{emailError ? 'form-control is-invalid active': emailChecked ?'form-control is-valid active':'form-control'}"
                           placeholder="E-mail"
                           bind:value={patientEmail}
                    />
                    <div class="invalid-feedback position-absolute">{ emailError }</div>
                </div>
            </div>
        </div>
        <div class="accordion" id="accordion">
            <div class="row justify-content-center mb-4">
                <div class="col-sm-12 col-md-8 col-lg-6 col-xl-12 p-0">
                    <div class="row justify-content-center">
                        <div class="col-6 d-flex align-items-center justify-content-center">
                            <button id="headingOne"
                                    class="btn btn-primary btn-lg fw-bold choose-button {nurseBtn ? 'active' : ''}"
                                    data-mdb-toggle="collapse"
                                    data-mdb-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                    on:click={needNurse}
                            >
                                Nurse
                            </button>
                        </div>
                        <div class="col-6 d-flex align-items-center justify-content-center">
                            <button class="btn btn-primary btn-lg fw-bold choose-button {doctorBtn ? 'active' : ''}"
                                    data-mdb-toggle="collapse"
                                    data-mdb-target="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                    on:click={needDoctor}
                            >
                                Doctor
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 p-0">
                    <div id="collapseOne" class="accordion-collapse collapse"
                         data-mdb-parent="#accordion">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-outline mb-4 mt-3 text-start message-block">
                                    <textarea
                                            class="{nurseDescError ? 'form-control is-invalid active': nurseDescChecked ?'form-control is-valid active':'form-control'}"
                                            id="nurses_message" rows="4"
                                            bind:value={patientNurseDesc}
                                    ></textarea>
                                    <label id="nurses_label" class="form-label" for="nurses_message">What do you need the nurse for?<br>This will help them to decide if they are suited/specialized for your case.<br><b>Don't enter any contact information yet.</b></label>
                                    <div class="invalid-feedback position-absolute">{ nurseDescError }</div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-2">
                            <button class="btn btn-primary btn-lg fw-bold {publishDisabled ? 'disabled' : ''}"
                                    on:click={publishWebform}>
                                Publish
                            </button>
                        </div>
                    </div>
                    <div id="collapseTwo" class="accordion-collapse collapse"
                         data-mdb-parent="#accordion">
                        <div class="row">
                            <div class="text-center">
                                <span class="d-block fw-bold">
                                    Doctor's specializations
                                </span>
                                <div class="row">
                                    <div class="select-control my-3 position-relative {!docSpecChecked || !docSpec ? 'placeholder-init' : ''}">
                                        <select
                                                id="docSpec"
                                                class="select form-control"
                                                bind:value={docSpec}
                                        >
                                            <option value="" disabled selected><span class="text-muted">Select doctor's specialization</span>
                                            </option>
                                            {#each docSpecList as item}
                                                <option class="text-danger"
                                                        value="{item.name}">{item.name}</option>
                                            {/each}
                                        </select>
                                        {#if !docSpec && docSpecChecked}
                                            <span class="position-absolute doc-spec-error position-absolute text-danger">{ docSpecError }</span>
                                        {/if}
                                    </div>
                                    <hr class="mt-3">
                                </div>
                                <div class="form-outline mb-4 mt-3 text-start message-block">
                                    <textarea
                                            class="{docDescError ? 'form-control is-invalid active': docDescChecked ?'form-control is-valid active':'form-control'}"
                                            id="doctors_message" rows="4"
                                            bind:value={patientDocDesc}
                                    ></textarea>
                                    <label id="doctors_label" class="form-label" for="doctors_message">What do you need the doctor for?<br>This will help them to decide if they are suited/specialized for your case.<br><b>Don't enter any contact information yet.</b></label>
                                    <div class="invalid-feedback position-absolute">{ docDescError }</div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-2">
                            <button class="btn btn-primary btn-lg fw-bold {publishDisabled ? 'disabled' : ''}"
                                    on:click={publishWebform}>
                                Publish
                            </button>
                        </div>
                        {#if feedback}
                            <div class="mt-4">
                                {#if feedback.hint}
                                    <span class="small d-block text-start" style="left: 0;">hint: <span
                                            class="text-info">{feedback.hint}</span></span>
                                {/if}
                                {#if feedback.details}
                                    <span class="small d-block text-start" style="left: 0;">details: <span
                                            class="text-danger">{feedback.details}</span></span>
                                {/if}
                                {#if feedback.code}
                                    <span class="small d-block text-start" style="left: 0;">code: <span
                                            class="text-info">{feedback.code}</span></span>
                                {/if}
                                {#if feedback.message}
                                    <span class="small d-block text-start" style="left: 0;">message: <span
                                            class="text-info">{feedback.message}</span></span>
                                {/if}
                            </div>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
